import React, { useState } from "react";
import { Row, Col, Card, Modal, Image, Typography, Button } from "antd";
import styled from "styled-components";

import { IntroTitle } from "../index";
import { MEMBER_ITEMS } from "../../data";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const Team = () => {
  const [member, setMember] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Wrapper id="team">
      <Modal
        title="Member Detail"
        open={isModalOpen}
        onCancel={handleCancel}
        closeIcon={false}
        footer={null}
        width="70%"
      >
        <Row gutter={[40, 40]}>
          <Col xs={24} md={12} xl={12}>
            <Image src={member.img} preview={false} />
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Title level={3}>{member.name}</Title>
            <Title level={5}>Role: {member.role}</Title>
            <Title level={5}>Phone: {member.phone}</Title>
            <Title level={5}>Location: {member.location}</Title>
            <Title level={4}>{member.subject}</Title>
            <ParagraphWrapper italic>{member.description}</ParagraphWrapper>
          </Col>
        </Row>
      </Modal>
      <IntroTitle
        badge="TEAM"
        title="Our Hardworking"
        colorTitle="Team"
        description="We are team of talented developers making websites with modern web frameworks"
      />
      <ButtonWrapper>
        <Button type="primary" size="large" href="/team">
          All members
        </Button>
      </ButtonWrapper>
      <ContentWrapper>
        <Row gutter={[40, 40]}>
          {MEMBER_ITEMS.map((item, index) => {
            return (
              item.main_visiable === true && (
                <Col key={index} xs={24} md={12} xl={6}>
                  <Card
                    cover={
                      <img
                        alt="port-img"
                        src={item.img}
                        style={{ height: "340px" }}
                      />
                    }
                    hoverable
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal();
                      setMember(item);
                    }}
                  >
                    <Meta title={item.name} description={item.role} />
                  </Card>
                </Col>
              )
            );
          })}
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f6f9fe;
  padding: 50px 0px 20px 0px;
`;

const ContentWrapper = styled.div`
  padding: 40px;

  .ant-card-hoverable:hover {
    transform: translate(0px, -20px);
    transition-duration: 0.5s !important;
    transition: inherit;
    box-shadow: 0px 4px 15px rgba(237, 137, 137, 0.85);
  }
`;

const ParagraphWrapper = styled(Paragraph)`
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default Team;
