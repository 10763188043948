import React from "react";
import { Card } from "antd";
import styled from "styled-components";

import { ThreeDHover } from "../index";

const { Meta } = Card;

const ServiceCard = ({ img, title, description }) => {
  return (
    <Wrapper>
      <ThreeDHover>
        <Card
          hoverable
          cover={<img style={{ height: "300px" }} alt="example" src={img} />}
        >
          <Meta title={title} description={description} />
        </Card>
      </ThreeDHover>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-card-meta-title {
    font-size: 24px;
    font-weight: bold;
  }

  .ant-card-meta-description {
    font-size: 18px;
  }
`;

export default ServiceCard;
