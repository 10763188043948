import React from "react";
import { Anchor, Button } from "antd";
import styled from "styled-components";

import Styles from "../../Styles";

const Navbar = () => {
  return (
    <Wrapper>
      <Logo href="/" type="link">
        Fortunes Tech LTD
      </Logo>
      <AnchorWrapper
        direction="horizontal"
        items={[
          {
            key: "home",
            href: "/#home",
            title: "Home",
          },
          {
            key: "about",
            href: "/#about",
            title: "About",
          },
          {
            key: "services",
            href: "/#services",
            title: "Services",
          },
          {
            key: "portfolio",
            href: "/#portfolio",
            title: "Portfolio",
          },
          // {
          //   key: "team",
          //   href: "/#team",
          //   title: "Team",
          // },
          {
            key: "contact",
            href: "/#contact",
            title: "Contact",
          },
        ]}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 35px;
  z-index: 40;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);

  .ant-btn {
    height: auto;
  }

  .ant-btn:hover {
    color: white !important;
  }
`;

const AnchorWrapper = styled(Anchor)`
  background-color: transparent;
  margin-right: 40px;
  @media screen and (max-width: 576px) {
    margin-right: 10px;
  }

  .ant-anchor-link-title {
    color: white !important;
    font-size: 24px;
    font-weight: bold;

    @media screen and (max-width: 576px) {
      font-size: 10px;
    }
  }

  .ant-anchor-link-title:hover {
    color: ${Styles.colorPrimary} !important;
  }

  .ant-anchor-link-title-active {
    color: ${Styles.colorPrimary} !important;
  }
`;

const Logo = styled(Button)`
  font-size: 28px;
  font-weight: bold;
  color: ${Styles.colorPrimary};
  margin-left: 40px;

  @media screen and (max-width: 576px) {
    font-size: 12px;
    margin-left: 10px;
  }
`;

export default Navbar;
