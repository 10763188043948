import IMG_PROJECT1_MAIN from "../assets/img/projects/project2/main.jpg";
import IMG_PROJECT1_SUB1 from "../assets/img/projects/project2/detail1.jpg";
import IMG_PROJECT1_SUB2 from "../assets/img/projects/project2/detail2.jpg";
import IMG_PROJECT1_SUB3 from "../assets/img/projects/project2/detail3.jpg";
import IMG_PROJECT1_SUB4 from "../assets/img/projects/project2/detail4.jpg";

import IMG_PROJECT2_MAIN from "../assets/img/projects/project6/main.jpg";
import IMG_PROJECT2_SUB1 from "../assets/img/projects/project6/detail1.jpg";
import IMG_PROJECT2_SUB2 from "../assets/img/projects/project6/detail2.jpg";
import IMG_PROJECT2_SUB3 from "../assets/img/projects/project6/detail3.jpg";
import IMG_PROJECT2_SUB4 from "../assets/img/projects/project6/detail4.jpg";

import IMG_PROJECT3_MAIN from "../assets/img/projects/project3/main.jpg";
import IMG_PROJECT3_SUB1 from "../assets/img/projects/project3/detail1.jpg";
import IMG_PROJECT3_SUB2 from "../assets/img/projects/project3/detail2.jpg";
import IMG_PROJECT3_SUB3 from "../assets/img/projects/project3/detail3.jpg";
import IMG_PROJECT3_SUB4 from "../assets/img/projects/project3/detail4.jpg";

import IMG_PROJECT4_MAIN from "../assets/img/projects/project1/main.jpg";
import IMG_PROJECT4_SUB1 from "../assets/img/projects/project1/detail1.jpg";
import IMG_PROJECT4_SUB2 from "../assets/img/projects/project1/detail2.jpg";
import IMG_PROJECT4_SUB3 from "../assets/img/projects/project1/detail3.jpg";
import IMG_PROJECT4_SUB4 from "../assets/img/projects/project1/detail4.jpg";

import IMG_PROJECT5_MAIN from "../assets/img/projects/project4/main.jpg";
import IMG_PROJECT5_SUB1 from "../assets/img/projects/project4/detail1.jpg";
import IMG_PROJECT5_SUB2 from "../assets/img/projects/project4/detail2.jpg";
import IMG_PROJECT5_SUB3 from "../assets/img/projects/project4/detail3.jpg";
import IMG_PROJECT5_SUB4 from "../assets/img/projects/project4/detail4.jpg";

import IMG_PROJECT6_MAIN from "../assets/img/projects/project5/main.jpg";
import IMG_PROJECT6_SUB1 from "../assets/img/projects/project5/detail1.jpg";
import IMG_PROJECT6_SUB2 from "../assets/img/projects/project5/detail2.jpg";
import IMG_PROJECT6_SUB3 from "../assets/img/projects/project5/detail3.jpg";
import IMG_PROJECT6_SUB4 from "../assets/img/projects/project5/detail4.jpg";

import IMG_PROJECT7_MAIN from "../assets/img/projects/project7/main.jpg";
import IMG_PROJECT7_SUB1 from "../assets/img/projects/project7/detail1.jpg";
import IMG_PROJECT7_SUB2 from "../assets/img/projects/project7/detail2.jpg";
import IMG_PROJECT7_SUB3 from "../assets/img/projects/project7/detail3.jpg";
import IMG_PROJECT7_SUB4 from "../assets/img/projects/project7/detail4.jpg";

import IMG_PROJECT8_MAIN from "../assets/img/projects/project8/main.jpg";
import IMG_PROJECT8_SUB1 from "../assets/img/projects/project8/detail1.jpg";
import IMG_PROJECT8_SUB2 from "../assets/img/projects/project8/detail2.jpg";
import IMG_PROJECT8_SUB3 from "../assets/img/projects/project8/detail3.jpg";
import IMG_PROJECT8_SUB4 from "../assets/img/projects/project8/detail4.jpg";

const PORTFOLIO_ITEMS = [
  {
    main_visible: true,
    link: "https://driveflux.com/",
    img: [
      IMG_PROJECT1_MAIN,
      IMG_PROJECT1_SUB1,
      IMG_PROJECT1_SUB2,
      IMG_PROJECT1_SUB3,
      IMG_PROJECT1_SUB4,
    ],
    title: "Driver Flux",
    type: "Lending App",
    description:
      "The website is about lending cars and motorcycles in Malaysia. The designs were provided by the client, and We did the rest from database design, development, and deployment to AWS. We used Node, React and mongodb as a tech stack and integrated third party services like Stripe for payment and Mailjet for mail integration. We have developed the MVP in 6 months and now People in Malaysia are actively using these services.",
  },
  {
    main_visible: true,
    link: "https://www.mgmresorts.com/",
    img: [
      IMG_PROJECT2_MAIN,
      IMG_PROJECT2_SUB1,
      IMG_PROJECT2_SUB2,
      IMG_PROJECT2_SUB3,
      IMG_PROJECT2_SUB4,
    ],
    title: "MGM Resorts",
    type: "Booking App",
    description:
      "MGM Resorts International is an S&P 500® global gaming and entertainment company with national and international locations featuring best-in-class hotels and casinos, state-of-the-art meetings and conference spaces, incredible live and theatrical entertainment experiences, and an extensive array of restaurant, nightlife and retail offerings. We developed Casino feature with 8 months using several tech stacks and third party integrations.",
  },
  {
    main_visible: true,
    link: "https://www.isluxury.com/",
    img: [
      IMG_PROJECT3_MAIN,
      IMG_PROJECT3_SUB1,
      IMG_PROJECT3_SUB2,
      IMG_PROJECT3_SUB3,
      IMG_PROJECT3_SUB4,
    ],
    title: "Is Luxury",
    type: "Booking App",
    description:
      "LUXURY Estate Management, a division of IS LUXURY, is a full-service home management company specializing in the maintenance management of private luxury residences in premier luxury Las Vegas Valley communities. Their goal is to provide exceptional service to our property owners while maintaining a high standard of professionalism and integrity. We have developed the MVP in 5 months and now People are actively using these services.",
  },
  {
    main_visible: false,
    link: "https://droneblocks.io/",
    img: [
      IMG_PROJECT4_MAIN,
      IMG_PROJECT4_SUB1,
      IMG_PROJECT4_SUB2,
      IMG_PROJECT4_SUB3,
      IMG_PROJECT4_SUB4,
    ],
    title: "Drone Blocks",
    type: "Education App",
    description:
      "The site is about teaching kids copmuter science with Tello drones. Kids can actually develop a program that controls Tello drones using drag and drop editor. The job involed customizing the editor for Drones and finally turning the generated code blocks into something that Tello drone can understand. You can check how it works from here. https://dev.droneblocks.io/ It was quite interesting and challening project.",
  },
  // {
  //   main_visible: false,
  //   link: "https://www.lyft.com/",
  //   img: [
  //     IMG_PROJECT5_MAIN,
  //     IMG_PROJECT5_SUB1,
  //     IMG_PROJECT5_SUB2,
  //     IMG_PROJECT5_SUB3,
  //     IMG_PROJECT5_SUB4,
  //   ],
  //   title: "Lyft",
  //   type: "Lending App",
  //   description:
  //     "The website is about booking car for personal or business purpose. Any people can book a suitable car and any drivers also apply for any cars. The company just offers cars. Then, drivers and riders are free people. We used Node, React, Next.js and mongodb as a tech stack and integrated third party services like Stripe for payment and Mailjet for mail integration.",
  // },
  {
    main_visible: false,
    link: "https://www.vrbo.com/",
    img: [
      IMG_PROJECT6_MAIN,
      IMG_PROJECT6_SUB1,
      IMG_PROJECT6_SUB2,
      IMG_PROJECT6_SUB3,
      IMG_PROJECT6_SUB4,
    ],
    title: "Vrbo",
    type: "Booking App",
    description:
      "Vrbo is a global vacation rental community with more than 2 million unique properties that seeks to connect people with the people and places they love. My main task was to develop a adding property. People could not only book a house, but also advertise their properties. We used Node, React and mongodb as a tech stack and integrated third party services like Stripe for payment and Mailjet for mail integration.",
  },
  // {
  //   main_visible: false,
  //   link: "https://www.wearejude.com/",
  //   img: [
  //     IMG_PROJECT8_MAIN,
  //     IMG_PROJECT8_SUB1,
  //     IMG_PROJECT8_SUB2,
  //     IMG_PROJECT8_SUB3,
  //     IMG_PROJECT8_SUB4,
  //   ],
  //   title: "Jude",
  //   type: "Healthcare App",
  //   description:
  //     "Jude is a healthcare company here to smash bladder taboos, open up conversations about incontinence and help you get on with life, leak-free. We have developed the MVP in 3 months and now People are actively using these services.",
  // },
  {
    main_visible: false,
    link: "https://www.gopuff.com/",
    img: [
      IMG_PROJECT7_MAIN,
      IMG_PROJECT7_SUB1,
      IMG_PROJECT7_SUB2,
      IMG_PROJECT7_SUB3,
      IMG_PROJECT7_SUB4,
    ],
    title: "Gopuff Food Delivery",
    type: "Booking App",
    description:
      "This website is about booking food delivery. We used Node, Lodash and mongodb as a tech stack and integrated third party services like Stripe for payment integration. We have implemented the MVP in 5 months and now People are actively using these services.",
  },
];

export default PORTFOLIO_ITEMS;
