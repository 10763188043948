import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";

import { IntroTitle, ServiceCard } from "../index";

import IMG_SERVICE1 from "../../assets/img/services/web_development.jpg";
import IMG_SERVICE2 from "../../assets/img/services/app_development.png";
import IMG_SERVICE3 from "../../assets/img/services/devops.png";

const SERVICE_ITMES = [
  {
    img: IMG_SERVICE1,
    title: "Web Development",
    description:
      "We deliver expertly crafted websites that elevate your brand and drive results.",
  },
  {
    img: IMG_SERVICE2,
    title: "App Development",
    description:
      "We build innovative and intuitive mobile and web apps that engage your users and enhance their experience.",
  },
  {
    img: IMG_SERVICE3,
    title: "DevOps",
    description:
      "We optimize your software delivery pipeline with agile methodologies and automation to ensure efficient and reliable deployment.",
  },
];

const Services = () => {
  return (
    <Wrapper id="services">
      <IntroTitle
        badge="SERVICES"
        title="Check Our"
        colorTitle="Services"
        description="Outsmart the competition with best-in-class digital marketing services."
      />
      <ContentWrapper>
        <Row gutter={[40, 40]}>
          {SERVICE_ITMES?.map((item, index) => (
            <Col key={index} className="gutter-row" xs={24} md={8} xl={8}>
              <ServiceCard
                img={item.img}
                title={item.title}
                description={item.description}
              />
            </Col>
          ))}
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 90px 0px 20px 0px;
`;

const ContentWrapper = styled.div`
  padding: 40px 40px;
`;

export default Services;
