import React, { useRef, useState } from "react";
import {
  Row,
  Col,
  Avatar,
  Typography,
  Input,
  Button,
  notification,
  Statistic,
} from "antd";
import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

import { IntroTitle } from "../index";
import Styles from "../../Styles";

const { Title } = Typography;
const { TextArea } = Input;

const Contact = () => {
  const formRef = useRef();
  const [api, contextHolder] = notification.useNotification();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const openNotificationWithIcon = ({ type, msg }) => {
    api[type]({
      message: msg,
    });
  };

  const handleSend = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_o42vykc",
        "template_o6xxvbc",
        formRef.current,
        "LL0ZqHo1wyJH8QMuA"
      )
      .then(
        (result) => {
          openNotificationWithIcon({
            type: "success",
            msg: "Your email has been sent!",
          });
        },
        (error) => {
          openNotificationWithIcon({
            type: "error",
            msg: "Your email has been failed!",
          });
        }
      );

    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <Wrapper id="contact">
      {contextHolder}
      <IntroTitle
        badge="CONTACT"
        colorTitle="Contact Us"
        description="Have a question? Need info? Want to say hello?"
      />
      <ContentWrapper>
        <Row gutter={[40, 40]}>
          <Col xs={24} md={24} xl={24}>
            <ContactCard>
              <Title level={3}>United Kingdom Headquarter</Title>
              <StatisticWrapper
                value="5 Attwood Street, Liverpool, England, L4 0RJ"
                prefix={
                  <HomeOutlined
                    style={{
                      border: `2px dotted ${Styles.colorPrimary}`,
                      color: Styles.colorPrimary,
                      borderRadius: "100%",
                      padding: "5px",
                    }}
                  />
                }
              />
              <StatisticWrapper
                value="+44 7418378910"
                prefix={
                  <PhoneOutlined
                    style={{
                      border: `2px dotted ${Styles.colorPrimary}`,
                      color: Styles.colorPrimary,
                      borderRadius: "100%",
                      padding: "5px",
                    }}
                  />
                }
              />
            </ContactCard>
          </Col>
          {/* <Col xs={24} md={24} xl={12}>
            <ContactCard>
              <Title level={3}>Ukraine Headquarter</Title>
              <StatisticWrapper
                value="Opzerna 38-4, Horishni Plavni, Poltavska 39800, Ukraine"
                prefix={
                  <HomeOutlined
                    style={{
                      border: `2px dotted ${Styles.colorPrimary}`,
                      color: Styles.colorPrimary,
                      borderRadius: "100%",
                      padding: "5px",
                    }}
                  />
                }
              />
              <StatisticWrapper
                value="+380 94 7125184"
                prefix={
                  <PhoneOutlined
                    style={{
                      border: `2px dotted ${Styles.colorPrimary}`,
                      color: Styles.colorPrimary,
                      borderRadius: "100%",
                      padding: "5px",
                    }}
                  />
                }
              />
            </ContactCard>
          </Col> */}
        </Row>
        <br />
        <Row gutter={[40, 40]}>
          <Col xs={24} md={24} xl={12}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2377.0378576896433!2d-2.970053723534313!3d53.43203136830915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b21667fb2e81f%3A0x8ea30c037fa940fa!2s5%20Attwood%20St%2C%20Anfield%2C%20Liverpool%20L4%200RJ%2C%20UK!5e0!3m2!1sen!2sus!4v1701077361833!5m2!1sen!2sus"
              style={{ width: "100%", height: "450px", border: "0px" }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </Col>
          <Col xs={24} md={24} xl={12}>
            <FormWrapper name="contact" ref={formRef} onSubmit={handleSend}>
              <Row gutter={[40, 40]}>
                <Col span={12}>
                  <Input
                    size="large"
                    name="from_name"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Col>
                <Col span={12}>
                  <Input
                    size="large"
                    name="from_email"
                    placeholder="Your Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Col>
                <Col span={24}>
                  <Input
                    size="large"
                    name="subject"
                    placeholder="Subject"
                    required
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Col>
                <Col span={24}>
                  <TextArea
                    rows={6}
                    name="message"
                    size="large"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button size="large" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </FormWrapper>
          </Col>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 90px 0px 0px 0px;
`;

const ContentWrapper = styled.div`
  padding: 40px;

  .ant-typography {
    margin-top: 0.5em !important;
  }
`;

const ContactCard = styled.div`
  height: 200px;
  border-radius: 20px;
  box-shadow: 0px 2px 15px rgba(237, 137, 137, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

const AvatarWrapper = styled(Avatar)`
  background-color: transparent;
  color: ${Styles.colorPrimary};
  border: 3px dotted ${Styles.colorPrimary};
`;

const FormWrapper = styled.form`
  padding: 40px;
`;

const StatisticWrapper = styled(Statistic)`
  .ant-statistic-content {
    font-size: 17px !important;
  }

  @media screen and (max-width: 576px) {
    .ant-statistic-content {
      font-size: 10px !important;
    }
  }
`;

export default Contact;
