import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import styled from "styled-components";
import { Carousel, Avatar, Typography } from "antd";

// import IMG_TESTIMONIAL from "../../assets/img/testimonials-bg.jpg";

// import IMG_AVATAR1 from "../../assets/img/testimonials/testimonials-1.jpg";
// import IMG_AVATAR2 from "../../assets/img/testimonials/testimonials-2.jpg";
// import IMG_AVATAR3 from "../../assets/img/testimonials/testimonials-3.jpg";
// import IMG_AVATAR4 from "../../assets/img/testimonials/testimonials-4.jpg";
// import IMG_AVATAR5 from "../../assets/img/testimonials/testimonials-5.jpg";

const { Title, Paragraph } = Typography;

const TESTIMONIAL_ITEMS = [
  // {
  //   avatar: IMG_AVATAR1,
  //   name: "Aziz Ayman",
  //   company: "Driveflux",
  //   review:
  //     "All I can say to FortunesTech is a big Thanks. They were superteam for us. Creating the entire website from scratch. They made our dream into reality. Exceptional work and really love it.",
  // },
  // {
  //   avatar: IMG_AVATAR2,
  //   name: "Peony Li",
  //   company: "Jude",
  //   review:
  //     "They came in and started making an impact from the first day. Thanks for the contributions you made. Best wishes in your career.",
  // },
  // {
  //   avatar: IMG_AVATAR3,
  //   name: "William Hornbuckle",
  //   company: "MGM Resorts",
  //   review:
  //     "FortunesTech team went out of their way to understand our mission and tailor their approach to meet our goals. As a result, within only 3 months, our traffic is up by 20% and the quality has improved beyond expectation.",
  // },
  // {
  //   avatar: IMG_AVATAR4,
  //   name: "Yakir Gola",
  //   company: "Gopuff",
  //   review:
  //     'FortunesTech has been the "secret weapon" that has catapulted my business and set me FAR apart from my competition.You know you have got something special when people ask "What is your secret?',
  // },
  // {
  //   avatar: IMG_AVATAR5,
  //   name: "Ivan Sher",
  //   company: "IS LUXURY",
  //   review:
  //     "I had a great experience working with FortunesTech and wouldn’t hesitate to recommend them. The level of developing skill and efficiency was beyond the scope of my expectations!",
  // },
];

const Testimonials = () => {
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  return (
    <Wrapper>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          particles: {
            number: {
              value: 600,
              density: {
                enable: true,
                value_area: 2000,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.5,
                sync: false,
              },
            },
            size: {
              value: 10,
              random: true,
              anim: {
                enable: false,
                speed: 20,
                size_min: 0.1,
                sync: true,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              enable: true,
              speed: 1.5,
              direction: "bottom",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <Carousel autoplay>
        {TESTIMONIAL_ITEMS.map((testimonial, index) => (
          <div key={index}>
            <div style={{ textAlign: "center" }}>
              <Avatar
                src={testimonial.avatar}
                size={128}
                style={{ border: "10px solid rgba(255,255,255,0.2)" }}
              />
              <Title level={3} style={{ color: "white" }}>
                {testimonial.name}
              </Title>
              <Title level={5} style={{ color: "white" }}>
                {testimonial.company}
              </Title>
              <Paragraph
                strong
                italic
                style={{
                  fontSize: "18px",
                  color: "white",
                }}
              >
                {testimonial.review}
              </Paragraph>
            </div>
          </div>
        ))}
      </Carousel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-image: url();
  height: 400px;
  padding-top: 120px;
  padding-left: 40px;
  padding-right: 40px;
  filter: blur(0px);

  .ant-typography {
    margin-top: 0.5em !important;
  }

  .slick-dots-bottom {
    margin-bottom: 0px;
  }
`;

export default Testimonials;
