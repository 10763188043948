import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";

import { FeatureCard } from "../index";

import IMG_FEATURE1 from "../../assets/img/features/1.png";
import IMG_FEATURE2 from "../../assets/img/features/2.png";
import IMG_FEATURE3 from "../../assets/img/features/3.png";
import IMG_FEATURE4 from "../../assets/img/features/4.png";

const FEATURE_ITEMS = [
  {
    img: IMG_FEATURE1,
    title: "Modernization Strategy",
    description:
      "Transform your monolithic legacy frameworks and infrastructure platforms to decrease maintenance costs and improve customer experience and satisfaction. We integrate new ideas and ways of working, developing more agile, flexible systems that evolve with the market and user expectations.",
  },
  {
    img: IMG_FEATURE2,
    title: "Cloud Migration & Optimization",
    description:
      "To maximize your investment in cloud technologies, you need to determine the right mix of infrastructure and platform to improve scalability, stability, monitoring, maintainability, and cost-efficiency. Whether you’re working with AWS, GCP, Azure, or all three, FortuneTech is a certified partner.",
  },
  {
    img: IMG_FEATURE3,
    title: "Fast project kick-off and easy integration",
    description:
      "Years of experience allow us to start and build projects quickly and deliver results fast. We follow a streamlined development process, ensuring that we both create new products and integrate our work seamlessly into your existing business processes.",
  },
  {
    img: IMG_FEATURE4,
    title: "Cybersecurity",
    description:
      "To stay on top of emerging trends and protect your company brand and customers from emerging threats, you must remain vigilant by investing in enterprise security operations that tie security objectives to business requirements. We focus on analyzing and improving the security of platforms technologies and applications.",
  },
];

const Features = () => {
  return (
    <Wrapper>
      <Row gutter={[40, 40]}>
        {FEATURE_ITEMS?.map((item, index) => (
          <Col key={index} className="gutter-row" xs={24} md={12} xl={6}>
            <FeatureCard
              img={item.img}
              title={item.title}
              description={item.description}
            />
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 50px 40px;
`;

export default Features;
