import React from "react";
import { Card } from "antd";

import { ThreeDHover } from "../index";

const { Meta } = Card;

const FeatureCard = ({ img, title, description }) => {
  return (
    <ThreeDHover>
      <Card hoverable cover={<img alt="example" src={img} />}>
        <Meta title={title} description={description} />
      </Card>
    </ThreeDHover>
  );
};

export default FeatureCard;
