import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Carousel,
  Image,
  Typography,
  Button,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { IntroTitle } from "../index";
import { PORTFOLIO_ITEMS } from "../../data";
import Styles from "../../Styles";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const Portfolio = () => {
  const [project, setProject] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Wrapper id="portfolio">
      <Modal
        title="Project Detail"
        open={isModalOpen}
        onCancel={handleCancel}
        closeIcon={false}
        footer={null}
        width="60%"
      >
        <LinkWrapper
          href={project.link}
          target="blank"
          type="primary"
          size="large"
          shape="circle"
          icon={<LinkOutlined />}
        />
        <CarouselWrapper dots={{ className: "carousel-dot" }} autoplay>
          {project.img?.map((item, index) => (
            <Image key={index} src={item} width="100%" preview={false} />
          ))}
        </CarouselWrapper>
        <Title level={2}>{project.title}</Title>
        <Title level={4}>{project.type}</Title>
        <ParagraphWrapper italic>{project.description}</ParagraphWrapper>
      </Modal>
      <IntroTitle
        badge="PORTFOLIO"
        title="Check Our"
        colorTitle="Portfolio"
        description="Explore the best in PPC, SEO and Web Projects by FortuneTech. Use the tabs to clarify all projects."
      />
      <ButtonWrapper>
        <Button type="primary" size="large" href="/portfolio">
          Read More
        </Button>
      </ButtonWrapper>
      <ContentWrapper>
        <Row gutter={[40, 40]}>
          {PORTFOLIO_ITEMS.map((item, index) => {
            return (
              item.main_visible === true && (
                <Col key={index} xs={24} md={8} xl={8}>
                  <Card
                    cover={
                      <img
                        alt="port-img"
                        src={item.img[0]}
                        style={{ height: "220px" }}
                      />
                    }
                    hoverable
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal();
                      setProject(item);
                    }}
                  >
                    <Meta title={item.title} description={item.type} />
                  </Card>
                </Col>
              )
            );
          })}
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f6f9fe;
  padding: 90px 0px 20px 0px;
`;

const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 12px;
    height: 14px;
    border-radius: 4px;
    background-color: ${Styles.colorPrimary};
  }
  > .slick-dots li.slick-active {
    width: 18px;
  }
  > .slick-dots li.slick-active button {
    width: 12px;
    height: 24px;
    top: -10px;
    border-radius: 4px;
    background: ${Styles.colorPrimary};
  }
`;

const ContentWrapper = styled.div`
  padding: 40px;

  .ant-card-hoverable:hover {
    transform: translate(0px, -20px);
    transition-duration: 0.5s !important;
    transition: inherit;
    box-shadow: 0px 4px 15px rgba(237, 137, 137, 0.85);
  }
`;

const ParagraphWrapper = styled(Paragraph)`
  font-size: 18px;
`;

const LinkWrapper = styled(Button)`
  position: absolute;
  z-index: 10;
  right: 40px;
  top: 60px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default Portfolio;
