import React from "react";
import { animated } from "react-spring";
import { use3dEffect } from "use-3d-effect";

const ThreeDHover = ({ children }) => {
  const ref = React.useRef(null);
  const { style, ...mouseHandlers } = use3dEffect(ref);

  return (
    <animated.div
      ref={ref}
      style={{
        ...style,
      }}
      {...mouseHandlers}
    >
      {children}
    </animated.div>
  );
};

export default ThreeDHover;
