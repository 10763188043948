import React from "react";
import styled from "styled-components";

const Loader = () => {
  return (
    <Wrapper>
      <div id="loader-container">
        {/* Start::Loader  */}
        <div className="loading">
          <div className="loading_circle loading_blue"></div>
          <div className="loading_circle loading_coral"></div>
          <div className="loading_circle loading_orange"></div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  #loader-container {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
  }

  /* Start::Loader  */
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    width: 170px;
    position: relative;
    margin: 0px;
    padding: 0px;
  }

  .loading_circle {
    border: 5px transparent solid;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 69%;
  }

  .loading_blue {
    top: 0px;
    border-top: 5px lightblue solid;
    animation-delay: 4s;
    animation: blue_animation 1.5s infinite;
  }

  .loading_blue:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: lightblue;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    box-shadow: 0px 0px 20px lightblue;
  }

  .loading_blue:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: lightblue;
    top: 8.8px;
    left: 9px;
    border-radius: 69%;
  }

  .loading_coral {
    left: 0px;
    bottom: 0px;
    border-top: 5px #fd5857 solid;
    animation: coral_animation 1.5s infinite;
  }

  .loading_coral:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #fd5857;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    box-shadow: 0px 0px 20px #fd5857;
  }

  .loading_coral:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: #fd5857;
    top: 8.8px;
    left: 9px;
    border-radius: 69%;
  }

  .loading_orange {
    right: 0px;
    bottom: 0px;
    border-top: 5px #f79d41 solid;
    animation: orange_animation 1.5s infinite;
  }

  .loading_orange:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #f79d41;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    box-shadow: 0px 0px 20px #f79d41;
  }

  .loading_orange:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: #f79d41;
    top: 8.8px;
    left: 9px;
    border-radius: 69%;
  }

  @keyframes blue_animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes coral_animation {
    0% {
      transform: rotate(240deg);
    }

    100% {
      transform: rotate(600deg);
    }
  }

  @keyframes orange_animation {
    0% {
      transform: rotate(120deg);
    }

    100% {
      transform: rotate(480deg);
    }
  }
  /* End::Loader */
`;

export default Loader;
