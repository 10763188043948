import React, { useCallback } from "react";
import { Carousel, Image, Typography, Button } from "antd";
import styled from "styled-components";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import Styles from "../../Styles";

import IMG_CAROUSEL1 from "../../assets/img/carousel/carousel-1.jpg";
import IMG_CAROUSEL2 from "../../assets/img/carousel/carousel-2.jpg";
import IMG_CAROUSEL3 from "../../assets/img/carousel/carousel-3.jpg";

import SVG_LOGO1 from "../../assets/img/logo/angular.svg";
import SVG_LOGO2 from "../../assets/img/logo/mongo.svg";
import SVG_LOGO3 from "../../assets/img/logo/mysql.svg";
import SVG_LOGO4 from "../../assets/img/logo/nodejs.svg";
import SVG_LOGO5 from "../../assets/img/logo/postgresql.svg";
import SVG_LOGO6 from "../../assets/img/logo/rails.svg";
import SVG_LOGO7 from "../../assets/img/logo/react.svg";
import SVG_LOGO8 from "../../assets/img/logo/redis.svg";
import SVG_LOGO9 from "../../assets/img/logo/vscode.svg";
import SVG_LOGO10 from "../../assets/img/logo/vue.svg";

const { Title } = Typography;

const CustomCarousel = () => {
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  return (
    <Wrapper id="home">
      <ParticlesWrapper
        id="img-particles"
        init={particlesInit}
        options={{
          particles: {
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 2000,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: ["image", "circle"],
              image: [
                {
                  src: SVG_LOGO1,
                },
                {
                  src: SVG_LOGO2,
                },
                {
                  src: SVG_LOGO3,
                },
                {
                  src: SVG_LOGO4,
                },
                {
                  src: SVG_LOGO5,
                },
                {
                  src: SVG_LOGO6,
                },
                {
                  src: SVG_LOGO7,
                },
                {
                  src: SVG_LOGO8,
                },
                {
                  src: SVG_LOGO9,
                },
                {
                  src: SVG_LOGO10,
                },
              ],
            },
            opacity: {
              value: 0.5,
              random: true,
              anim: {
                enable: true,
                speed: 1.5,
                opacity_min: 0.05,
                sync: true,
              },
            },
            size: {
              value: 20,
              random: true,
              anim: {
                enable: true,
                speed: 4,
                size_min: 0,
                sync: true,
              },
            },
            line_linked: {
              enable: true,
              distance: 70,
              color: "#ffffff",
              opacity: 0.8,
              width: 1,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          fullScreen: {
            enable: false,
          },
          retina_detect: true,
        }}
      />
      <BoardWrapper>
        <TitleWrapper level={1}>Welcome to Fortunes Tech LTD</TitleWrapper>
        <DescWrapper>
          We are lead in creative & innovative digital solution
        </DescWrapper>
        <br />
        <br />
        <ButtonWrapper type="primary" size="large" href="#about">
          GET STARTED
        </ButtonWrapper>
      </BoardWrapper>
      <CarouselWrapper
        dots={{ className: "carousel-dot" }}
        autoplay
        dotPosition="left"
        effect="fade"
      >
        <div>
          <ImageWrapper>
            <Image src={IMG_CAROUSEL1} width="100%" preview={false} />
          </ImageWrapper>
        </div>
        <div>
          <ImageWrapper>
            <Image src={IMG_CAROUSEL2} width="100%" preview={false} />
          </ImageWrapper>
        </div>
        <div>
          <ImageWrapper>
            <Image src={IMG_CAROUSEL3} width="100%" preview={false} />
          </ImageWrapper>
        </div>
      </CarouselWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  #img-particles {
    height: "100%";
  }
`;

const ParticlesWrapper = styled(Particles)`
  position: absolute;
  z-index: 10;
  height: 850px;
  width: 100%;

  @media screen and (max-width: 576px) {
    height: 250px;
  }
`;

const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 4px solid rgba(255, 255, 255, 0.6);
    background-color: transparent;
  }
  .slick-dots .slick-active {
    height: 32px !important;
  }
  .slick-dots li {
    height: 24px;
  }
  > .slick-dots li.slick-active button {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 4px solid ${Styles.colorPrimary};
    background: transparent;
  }
`;

const BoardWrapper = styled.div`
  @media screen and (max-width: 576px) {
    top: 5%;
  }
  position: absolute;
  z-index: 30;
  left: 10%;
  top: 30%;

  .ant-typography {
    margin-top: 0.5em !important;
    margin-bottom: 0em !important;
  }
`;

const TitleWrapper = styled(Title)`
  @media screen and (max-width: 576px) {
    font-size: 28px !important;
    color: white !important;
    font-weight: bold !important;
  }
  font-size: 64px !important;
  color: white !important;
  font-weight: bold !important;
`;

const DescWrapper = styled(Title)`
  @media screen and (max-width: 576px) {
    font-size: 18px !important;
    color: white !important;
  }
  font-size: 32px !important;
  color: white !important;
`;

const ImageWrapper = styled.div``;

const ButtonWrapper = styled(Button)``;

export default CustomCarousel;
