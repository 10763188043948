import React, { useRef } from "react";
import styled from "styled-components";
import { Row, Col, Image, Typography, Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { IntroTitle, ThreeDHover } from "../index";
import Styles from "../../Styles";

import IMG_ABOUT from "../../assets/img/about.jpg";
// import IMG_CLIENT1 from "../../assets/img/clients/client-1.png";
// import IMG_CLIENT2 from "../../assets/img/clients/client-2.png";
// import IMG_CLIENT3 from "../../assets/img/clients/client-3.png";
// import IMG_CLIENT4 from "../../assets/img/clients/client-4.png";
// import IMG_CLIENT5 from "../../assets/img/clients/client-5.png";
// import IMG_CLIENT6 from "../../assets/img/clients/client-6.png";

// const CLIENTS = [
//   { img: IMG_CLIENT1 },
//   { img: IMG_CLIENT2 },
//   { img: IMG_CLIENT3 },
//   { img: IMG_CLIENT4 },
//   { img: IMG_CLIENT5 },
//   { img: IMG_CLIENT6 },
// ];

const { Title, Paragraph } = Typography;

const About = () => {
  const slider = useRef(null);

  return (
    <Wrapper id="about">
      <IntroTitle
        badge="ABOUT"
        title="Find Out More"
        colorTitle="About Us"
        description="We are team of talented developers making websites with modern web frameworks"
      />
      <br />
      <br />

      <Row>
        <Col
          className="gutter-row"
          xs={24}
          md={12}
          xl={12}
          style={{ textAlign: "center" }}
        >
          <ThreeDHover>
            <Image preview={false} width="70%" src={IMG_ABOUT} />
          </ThreeDHover>
        </Col>

        <Col className="gutter-row" xs={24} md={12} xl={12}>
          <ContentWrapper>
            <Title
              level={3}
              style={{ color: Styles.colorPrimary, fontWeight: "bold" }}
            >
              Web Wizards at Work
            </Title>
            <Paragraph italic style={{ fontSize: "17px" }}>
              Just hatched in the digital realm, we’re the fresh faces
              revolutionizing web solutions with a dash of humor and a whole lot
              of creativity. Think of us as your friendly neighborhood web
              wizards, turning pixels into magic and ideas into digital
              masterpieces!
            </Paragraph>
            <br />
            <Paragraph strong>
              <blockquote>
                We believe that to build a product without a deep business and
                audience research is the same as shooting in the dark with the
                fingers crossed. Our expert team takes a systematic approach to
                develop digital experiences that step-by-step lead a business to
                success.
              </blockquote>
            </Paragraph>
            <br />
            <Paragraph strong>
              <blockquote>
                We believe that product usability is like love. You should care,
                listen, and you have to be willing to change and fix your
                infelicities. So we pay particular attention to user testing and
                aim to deliver better products that will be quickly-loved by
                people.
              </blockquote>
            </Paragraph>
          </ContentWrapper>
        </Col>
      </Row>
      <br />
      {/* <Row style={{ alignItems: "center" }}>
        <Col span={2} style={{ textAlign: "center" }}>
          <Button
            type="primary"
            shape="circle"
            onClick={() => slider.current.prev()}
            icon={<LeftOutlined />}
          />
        </Col>
        <Col span={20}>
          <Carousel slidesToShow={4} ref={slider} dots={false} autoplay>
            {CLIENTS.map((client, index) => (
              <ClientCard key={index}>
                <img
                  style={{ width: "50%" }}
                  src={client.img}
                  alt="about-img"
                />
              </ClientCard>
            ))}
          </Carousel>
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          <Button
            type="primary"
            shape="circle"
            onClick={() => slider.current.next()}
            icon={<RightOutlined />}
          />
        </Col>
      </Row> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f6f9fe;
  padding: 90px 0px 20px 0px;

  .slick-slide > div > div {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }
`;

const ContentWrapper = styled.div`
  width: 80%;
  margin-left: 10%;
`;

const ClientCard = styled.div`
  height: 100px;
`;

export default About;
