import React, { Suspense } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { ConfigProvider } from "antd";

import Styles from "./Styles";
import "./App.css";

import { Loader, Navbar, Footer, NotFound, Headbar } from "./components";

const MainPage = React.lazy(() => import("./pages/MainPage"));
const TeamPage = React.lazy(() => import("./pages/TeamPage"));
const PortfolioPage = React.lazy(() => import("./pages/PortfolioPage"));
const VerificationPage = React.lazy(() => import("./pages/VerificationPage"));
const PersonaPage = React.lazy(() => import("./pages/PersonaPage"));

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Styles.colorPrimary,
        },
      }}
    >
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Headbar />
          <Navbar />
          <Routes>
            <Route path="/" element={<MainPage />} />
            {/* <Route path="/team" element={<TeamPage />} /> */}
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/verification" element={<VerificationPage />} />
            <Route path="/persona" element={<PersonaPage />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/404" />}></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
