import React from "react";
import { Statistic } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import styled from "styled-components";

import Styles from "../../Styles";

const Headbar = () => {
  return (
    <Wrapper>
      <StatisticWrapper
        value="contact@fortunes.tech"
        prefix={
          <MailOutlined
            style={{
              border: `3px dotted white`,
              color: "white",
              borderRadius: "100%",
              padding: "3px",
              fontSize: "14px",
            }}
          />
        }
      />
      <StatisticWrapper
        value="+44 7418378910"
        prefix={
          <PhoneOutlined
            style={{
              border: `3px dotted white`,
              color: "white",
              borderRadius: "100%",
              padding: "3px",
              fontSize: "14px",
            }}
          />
        }
      />
      {/* <StatisticWrapper
        value="+380 94 7125184"
        prefix={
          <PhoneOutlined
            style={{
              border: `3px dotted white`,
              color: "white",
              borderRadius: "100%",
              padding: "3px",
              fontSize: "14px",
            }}
          />
        }
      /> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 40;
  width: 100%;
  height: 35px;
  background-color: ${Styles.colorPrimary};
  display: flex;
  align-items: center;
  padding-left: 80px;
`;

const StatisticWrapper = styled(Statistic)`
  margin-right: 20px;
  .ant-statistic-content {
    font-size: 17px !important;
    color: white;
  }
`;

export default Headbar;
