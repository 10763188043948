import React from "react";
import styled from "styled-components";

const NotFound = () => {
  return (
    <Wrapper>
      <h1>404 Page Not Found</h1>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export default NotFound;
