import React from "react";
import styled from "styled-components";
import { Typography } from "antd";

import Styles from "../../Styles";

const { Title, Text } = Typography;

const IntroTitle = ({ badge, title, colorTitle, description }) => {
  return (
    <Wrapper>
      <BadgeWrapper>
        <Badge level={5}>{badge}</Badge>
      </BadgeWrapper>
      <MainTitle>
        <Title level={2} style={{ fontWeight: "bold" }}>
          {title}&nbsp;
        </Title>
        <Title
          level={2}
          style={{ fontWeight: "bold", color: Styles.colorPrimary }}
        >
          {colorTitle}
        </Title>
      </MainTitle>
      <DescriptionWrapper>
        <Description>{description}</Description>
      </DescriptionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-typography {
    margin-top: 0.5em !important;
  }
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Badge = styled(Title)`
  width: fit-content;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #ffe6e6;
  color: ${Styles.colorPrimary} !important;
`;

const MainTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const DescriptionWrapper = styled.div`
  text-align: center;
`;

const Description = styled(Text)`
  font-weight: bold;
  padding: 0px 10px;
  font-size: 18px;
`;

export default IntroTitle;
