// import IMG_TEAM1 from "../assets/img/team/team-1.jpg";
// import IMG_TEAM2 from "../assets/img/team/team-2.jpg";
// import IMG_TEAM3 from "../assets/img/team/team-3.jpg";
// import IMG_TEAM4 from "../assets/img/team/team-4.jpg";

const MEMBER_ITEMS = [
  // {
  //   main_visiable: true,
  //   img: IMG_TEAM1,
  //   role: "CEO",
  //   name: "Walter White1",
  //   phone: "+1 234 4567",
  //   location: "Toronto",
  //   subject: "This is an example of portfolio detail",
  //   description:
  //     "This is an example of portfolio detail Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.",
  // },
  // {
  //   main_visiable: true,
  //   img: IMG_TEAM2,
  //   role: "Product Manager",
  //   name: "Walter White2",
  //   phone: "+1 234 4567",
  //   location: "Toronto",
  //   subject: "This is an example of portfolio detail",
  //   description:
  //     "This is an example of portfolio detail Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.",
  // },
  // {
  //   main_visiable: true,
  //   img: IMG_TEAM3,
  //   role: "CTO",
  //   name: "Walter White3",
  //   phone: "+1 234 4567",
  //   location: "Toronto",
  //   subject: "This is an example of portfolio detail",
  //   description:
  //     "This is an example of portfolio detail Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.",
  // },
  // {
  //   main_visiable: true,
  //   img: IMG_TEAM4,
  //   role: "accountant",
  //   name: "Walter White4",
  //   phone: "+1 234 4567",
  //   location: "Toronto",
  //   subject: "This is an example of portfolio detail",
  //   description:
  //     "This is an example of portfolio detail Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.",
  // },
  // {
  //   main_visiable: false,
  //   img: IMG_TEAM3,
  //   role: "senior developer",
  //   name: "Walter White5",
  //   phone: "+1 234 4567",
  //   location: "Toronto",
  //   subject: "This is an example of portfolio detail",
  //   description:
  //     "This is an example of portfolio detail Autem ipsum nam porro corporis rerum. Quis eos dolorem eos itaque inventore commodi labore quia quia. Exercitationem repudiandae officiis neque suscipit non officia eaque itaque enim. Voluptatem officia accusantium nesciunt est omnis tempora consectetur dignissimos. Sequi nulla at esse enim cum deserunt eius.",
  // },
];

export default MEMBER_ITEMS;
