import React from "react";
import styled from "styled-components";
import { Row, Col, Typography, Button } from "antd";

const { Text } = Typography;

const Footer = () => {
  return (
    <Wrapper>
      <Row>
        <Col xs={12} md={16} xl={18}>
          <TextWrapper>
            &copy; Copyright Fortunes Tech LTD. All rights Reserved.
          </TextWrapper>
        </Col>
        <Col xs={12} md={8} xl={6} style={{ textAlign: "right" }}>
          <TextWrapper>
            Designed by
            <Button type="link" href="/">
              Fortunes Tech LTD
            </Button>
          </TextWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f6f9fe;
  padding: 30px;
`;

const TextWrapper = styled(Text)`
  text-align: right;
`;

export default Footer;
